import {
  Flex,
  Text,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@aws-amplify/ui-react'
import './css/register-status-search.css'
import { RegisterRecord } from './types/RegisterStatusResult'

const RegisterStatusSearchResult = (props: any) => {
  let prevTagName = ''
  let prevRegisterdMail = ''
  let registerItemsWithMail = props.items.flatMap((data: RegisterRecord) =>
    data.registerItems.map(item => ({
      ...item,
      registerdMail: data.registerdMail,
    })),
  )
  registerItemsWithMail = registerItemsWithMail.sort((a: any, b: any) => {
    const result = a.registerdMail.localeCompare(b.registerdMail);

    // hogeが同じ場合、hoge2プロパティで比較
    if (result === 0) {
      return a.tagName.localeCompare(b.tagName);
    }

    // hogeプロパティでの比較結果を返す
    return result;
  })
  console.log(registerItemsWithMail)
  return (
    <>
      <Flex
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        width="80%"
      >
        <Text fontSize={'larger'} fontWeight={'bolder'} color="black">
          検索結果
        </Text>
      </Flex>
      <Flex
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        width="80%"
      >
        <Table
          highlightOnHover={true}
          variation="striped"
          size="small"
          backgroundColor={'white'}
          width="70%"
        >
          <TableHead>
            <TableRow>
              <TableCell as="th">登録メールアドレス</TableCell>
              <TableCell as="th">ONE UID</TableCell>
              <TableCell as="th">会員ステータス</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.items.map((item: RegisterRecord, index: React.Key) => {
              return (
                <TableRow>
                  <TableCell>
                    {item?.registerdMail ? item?.registerdMail : '未登録'}
                  </TableCell>
                  <TableCell>{item?.oneUid ? item?.oneUid : '-'}</TableCell>
                  <TableCell>
                    {item?.isPaidUser
                      ? item?.isPaidUser
                        ? '有償'
                        : 'フリー'
                      : '-'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Flex>

      <Flex
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        width="80%"
      >
        <Text fontSize={'larger'} fontWeight={'bolder'} color="black">
          タグ一覧
        </Text>
        <Text color="black">
          {props.items.reduce(
            (acc: number, data: RegisterRecord) =>
              acc + data.registerItems.length,
            0,
          )}
          件
        </Text>
      </Flex>

      <Flex
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        width="80%"
      >
        <Table
          highlightOnHover={true}
          variation="striped"
          size="small"
          backgroundColor={'white'}
          width="70%"
        >
          <TableHead>
            <TableRow>
              <TableCell as="th">登録メールアドレス</TableCell>
              <TableCell as="th">タグ名</TableCell>
              <TableCell as="th">配信メールアドレス</TableCell>
              <TableCell as="th">認証ステータス</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {registerItemsWithMail?.map((item: any, index: React.Key) => {
              const tagName = prevTagName === item.tagName && prevRegisterdMail === item.registerdMail ? '' : item.tagName
              const registerdMail = prevRegisterdMail === item.registerdMail ? '' : item.registerdMail
              prevTagName = item.tagName
              prevRegisterdMail = item.registerdMail
              return (
                <TableRow key={index}>
                  <TableCell>{registerdMail}</TableCell>
                  <TableCell>{tagName}</TableCell>
                  <TableCell>{item.deliveryMail}</TableCell>
                  <TableCell>
                    {item.isAuthenticated ? '認証済み' : '未認証'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Flex>
    </>
  )
}

export default RegisterStatusSearchResult
