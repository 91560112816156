import { Theme } from '@aws-amplify/ui-react'

const theme: Theme = {
  name: 'cra-my-theme',
  tokens: {
    colors: {
      font: {
        primary: { value: '#008080' },
      },
    },
  },
}

export default theme
